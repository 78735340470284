import { IconKey, IconAnalyze, IconSettingsAutomation, IconBriefcase, IconReceipt } from '@tabler/icons';

const icons = {
  IconKey, IconAnalyze, IconSettingsAutomation, IconBriefcase, IconReceipt
};

const Rotinas = (user) => {

  const rotinas = {
    id: 'rotinas',
    title: 'Rotinas Fiscais',
    type: 'group',
    children: [
      ...(user.funcao === 'admin' ? [
        {
          id: 'automacoesCollapse',
          title: 'Automações',
          type: 'collapse',
          icon: icons.IconSettingsAutomation,
          breadcrumbs: false,
          children: [
            {
              id: 'ecac',
              title: 'DARF Unificado - DCTFWEB',
              type: 'item',
              url: '/rotinas/ecac',
              icon: icons.IconKey,
              breadcrumbs: false
            },
            {
              id: 'das',
              title: 'Simples Nacional - DAS',
              type: 'item',
              url: '/rotinas/das',
              icon: icons.IconKey,
              breadcrumbs: false
            },
          ]
        },
      ] : []),
      ...(user.funcao === 'admin' ? [
        {
          id: 'publicacoesCollapse',
          title: 'Publicações',
          type: 'collapse',
          icon: icons.IconBriefcase,
          breadcrumbs: false,
          children: [
            {
              id: 'mensais',
              title: 'Impostos Mensais - Acessorias',
              type: 'item',
              url: '/rotinas/ImpostosMensais',
              icon: icons.IconReceipt,
              breadcrumbs: false
            },
            {
              id: 'publicarObrigacoes',
              title: 'Publicar em lote para consolidação',
              type: 'item',
              url: '/rotinas/publicarObrigacoes',
              icon: icons.IconReceipt,
              breadcrumbs: false
            },
            {
              id: 'impostosMensaisConecta',
              title: 'Impostos Mensais - Conecta',
              type: 'item',
              url: '/rotinas/impostosMensaisConecta',
              icon: icons.IconReceipt,
              breadcrumbs: false
            },
          ]
        },        
      ] : []),
      {
        id: 'clientPanel',
        title: 'Diagnostico Fiscal Clientes',
        type: 'item',
        url: '/rotinas/clientPanel',
        icon: icons.IconAnalyze,
        breadcrumbs: false
      }
    ]
  };

  return rotinas
}

export default Rotinas;
