
import { IconTicket, IconCalendar, IconReceipt, IconReportMoney, IconFileSearch, IconArrowAutofitRight, IconFileInvoice } from '@tabler/icons';

const icons = { IconTicket, IconCalendar, IconReceipt, IconReportMoney, IconFileSearch, IconArrowAutofitRight, IconFileInvoice };


const MenuCliente = (user) => {

  let title;
  switch (user.funcao) {
    case 'cliente':
      title = 'Minha empresa';
      break;
    default:
      title = 'Dados do Cliente';
      break;
  }

  const menuCliente = {
    id: 'cliente',
    type: 'group',
    title: title,
    children: [
      // {
      //   id: 'chamados',
      //   title: 'Solicitações',
      //   type: 'item',
      //   url: '/cliente/chamados',
      //   icon: icons.IconTicket,
      //   breadcrumbs: false
      // },
      ...((user.funcao === 'admin' || user.funcao === 'analista') ? [
        {
          id: 'mensalidades',
          title: 'Honorarios LCR',
          type: 'item',
          url: '/cliente/mensalidades',
          icon: icons.IconReportMoney,
          breadcrumbs: false
        },
      ] : []),
      ...(user.funcao === 'cliente' ? [
        {
          id: 'mensalidadesCollapse',
          title: 'Honorarios LCR',
          type: 'collapse',
          icon: icons.IconReportMoney,
          breadcrumbs: false,
          children: [
            {
              id: 'mensalidadesPagar',
              title: 'Mês atual',
              type: 'item',
              url: '/cliente/mensalidadesAtuais',
              icon: icons.IconArrowAutofitRight,
              breadcrumbs: false
            },
            {
              id: 'mensalidadesCliente',
              title: 'Consultar histórico',
              type: 'item',
              url: '/cliente/mensalidades',
              icon: icons.IconFileSearch,
              breadcrumbs: false
            },
          ]
        },
      ] : []),
      ...((user.funcao === 'admin' || user.funcao === 'analista') ? [
        // {
        //   id: 'impostos',
        //   title: 'Impostos',
        //   type: 'item',
        //   url: '/cliente/impostos',
        //   icon: icons.IconCalendar,
        //   breadcrumbs: false
        // },
      ] : []),
      ...(user.funcao === 'cliente' ? [
        {
          id: 'impostosCollapse',
          title: 'Meus Impostos',
          type: 'collapse',
          icon: icons.IconReceipt,
          breadcrumbs: false,
          children: [
            {
              id: 'impostosPagar',
              title: 'Consultar',
              type: 'item',
              url: '/cliente/impostosAtuais',
              icon: icons.IconFileSearch,
              breadcrumbs: false
            },
            // {
            //   id: 'impostosCliente',
            //   title: 'Consultar histórico',
            //   type: 'item',
            //   url: '/cliente/impostos',
            //   icon: icons.IconCalendar,
            //   breadcrumbs: false
            // },
          ]
        },
      ] : []),
      // ...((user.funcao === 'admin' || user.funcao === 'analista') ? [
      //   {
      //     id: 'arquivos',
      //     title: 'Arquivos dos clientes',
      //     type: 'item',
      //     url: '/cliente/arquivos',
      //     icon: icons.IconCalendar,
      //     breadcrumbs: false
      //   }
      // ] : []),
      ...((user.funcao === 'cliente') ? [
        {
          id: 'compromissos',
          title: 'Minha Agenda',
          type: 'item',
          url: '/cliente/compromissos',
          icon: icons.IconCalendar,
          breadcrumbs: false
        }
      ] : []),
      ...((user.funcao === 'cliente') ? [
        {
          id: 'nfCollapse',
          title: 'Notas Fiscais',
          type: 'collapse',
          icon: icons.IconReceipt,
          breadcrumbs: false,
          children: [
            {
              id: 'emissaonf',
              title: 'Emissão de Nota Fiscal',
              type: 'item',
              url: '/cliente/emissaonf',
              icon: icons.IconFileInvoice,
              breadcrumbs: false
            },
            {
              id: 'minhasNF',
              title: 'Minhas Notas Fiscais',
              type: 'item',
              url: '/cliente/minhasNF',
              icon: icons.IconFileInvoice,
              breadcrumbs: false
            },
            {
              id: 'favorecidos',
              title: 'Meus Favorecidos',
              type: 'item',
              url: '/cliente/favorecidos',
              icon: icons.IconFileInvoice,
              breadcrumbs: false
            }

          ]
        },
      ] : []),      
    ]
  };

  return menuCliente;
};

export default MenuCliente;